////
/// ============================================================================
/// GDPR BANNER
///
/// 01. Base
/// 02. Positions
/// 03. Styles
/// ============================================================================
////


/**
 * Base
 */
.gdpr-banner {
	background: #fff;
	color: #000;
	display: flex;
	flex-wrap: nowrap;
	font: 15px/1.5 system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	opacity: 1;
	overflow: hidden;
	padding: 1.5em;
	transition: opacity 0.2s ease;

	@media screen and (max-width: 480px) {
		align-items: unset;
		flex-direction: column;
	}

	svg {
		fill: currentColor;
		flex-shrink: 0;
		height: 2em;
		margin-right: 1em;
		width: 2em;
		&:not(:root) {
			overflow: hidden;
		}

		@media screen and (max-width: 480px) {
			display: none;
		}
	}
	p {
		margin: 0;
	}
	a {
		color: #000;
		cursor: pointer;
		display: inline-block;
		opacity: .8;
		padding: 0 .2em;
		&:active, &:visited {
			color: inherit;
		}
		&:hover {
			color: #000;
			opacity: 1;
		}
	}

	&--hide {
		bottom: auto !important;
		left: -9999px !important;
		opacity: 0;
		position: fixed !important;
		right: auto !important;
	}

	&__content {
		align-items: center;
		display: flex;
		flex: 1;
		padding-right: 1em;

		@media screen and (max-width: 480px) {
			margin-bottom: 1em;
			padding-right: 0;
		}
	}

	&__accept {
		align-content: space-between;
		align-items: center;
		background-color: #0069ed;
		border: 0;
		border-radius: 0;
		color: #fff;
		cursor: pointer;
		display: inline-block;
		display: flex;
		font-family: inherit;
		font-size: 100%;
		font-weight: 700;
		justify-content: center;
		line-height: 1.5;
		margin: 0;
		min-width: 160px;
		overflow: visible;
		padding: 0.5em 1em;
		text-align: center;
		text-decoration: none;
		text-transform: none;
		transition: background 250ms ease-in-out, color 250ms ease-in-out, opacity 150ms ease;
		white-space: nowrap;

		&:hover,
		&:focus {
			background-color: #0053ba;
		}

		&:focus {
			outline: none;
		}

		@media screen and (max-width: 480px) {
			flex: 1;
		}
	}
}


/**
 * Positions
 */
.gdpr-banner--sticky {
	position: fixed;
	z-index: 9999;

	&-top {
		left: 0;
		right: 0;
		top: 0;
	}

	&-bottom {
		bottom: 0;
		left: 0;
		right: 0;
	}
}

.gdpr-banner--floating {
	flex-direction: column;
	position: fixed;
	z-index: 9999;
	width: 100%;

	@media screen and (min-width: 768px) {
		max-width: 480px;
	}

	svg {
		display: none;
	}

	.gdpr-banner__content {
		display: block;
		margin-bottom: 1em;
		padding-right: 0;
	}
	.gdpr-banner__accept {
		width: 100%;
	}

    // Top
    &-topleft {
        top: 0;

        @media screen and (min-width: 768px) {
            left: 1em;
            top: 1em;
        }
    }
    &-topcenter {
        left: 50%;
        right: auto;
        top: 0;
        transform: translateX(-50%);

        @media screen and (min-width: 768px) {
            top: 1em;
        }
    }
    &-topright {
        top: 0;

        @media screen and (min-width: 768px) {
            right: 1em;
            top: 1em;
        }
    }

    // Center
    &-center {
        left: 50%;
        right: auto;
        top: 50%;
        transform: perspective(1px) translateX(-50%) translateY(-50%);
    }

	// Bottom
	&-bottomleft {
		bottom: 0;

		@media screen and (min-width: 768px) {
			bottom: 1em;
			left: 1em;
		}
	}
	&-bottomcenter {
		bottom: 0;
		left: 50%;
		right: auto;
		transform: translateX(-50%);

		@media screen and (min-width: 768px) {
			bottom: 1em;
		}
	}
	&-bottomright {
		bottom: 0;

		@media screen and (min-width: 768px) {
			bottom: 1em;
			right: 1em;
		}
	}
}


/**
 * Styles
 */
.gdpr-banner--mono {
	background-color: #237afc;
	color: #fff;

	a {
		color: #fff;
		&:hover {
			color: #fff;
		}
	}

	.gdpr-banner__accept {
		background-color: transparent;
		border: 2px solid #fff;
		color: #fff;
		opacity: .8;
		&:hover,
		&:focus {
			background-color: transparent;
			opacity: 1;
		}
	}
}

.gdpr-banner--honeybee {
	background-color: #000;
	color: #fff;

	a {
		color: #fff;
		&:hover {
			color: #fff;
		}
	}

	.gdpr-banner__accept {
		background-color: #f1d600;
		color: #000;
		&:hover,
		&:focus {
			background-color: #fffc26;
		}
	}
}

.gdpr-banner--cosmo {
	background-color: #3937a3;
	color: #fff;

	a {
		color: #fff;
		&:hover {
			color: #fff;
		}
	}

	.gdpr-banner__accept {
		background-color: #e62576;
		color: #fff;
		&:hover,
		&:focus {
			background-color: #ff4b9c;
		}
	}
}

.gdpr-banner--elegant {
	background-color: #212121;
	color: #fff;
	padding: 0;

	a {
		color: #fff;
		&:hover {
			color: #fff;
		}
	}

	.gdpr-banner__content {
		padding: 2em;

		@media screen and (max-width: 480px) {
			margin-bottom: 0;
		}
	}

	.gdpr-banner__accept {
		background-color: transparent;
		border-left: 1px solid #626265;
		color: #fff;
		font-weight: 400;
		height: 100%;
		padding-bottom: 3em;
		padding-top: 3em;

		&:hover,
		&:focus {
			color: #dadada;
		}

		@media screen and (max-width: 480px) {
			border-left: 0;
			border-top: 1px solid #626265;
			padding-bottom: 1em;
			padding-top: 1em;
		}
	}

	&.gdpr-banner--floating {
		.gdpr-banner__content {
			margin-bottom: 0;
		}
		.gdpr-banner__accept {
			border-left: 0;
			border-top: 1px solid #626265;
			padding-bottom: 1em;
			padding-top: 1em;
		}
	}
}