////
/// ============================================================================
/// GDPR MODAL
/// ============================================================================
////

.gdpr-modal {
    bottom: 0;
    font: 15px/1.5 system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;

    &--overflow,
    &--overflow body {
        overflow: hidden;
    }

    &__overlay,
    &__content {
        -webkit-overflow-scrolling: touch;
        height: 100%;
        left: 0;
        overflow: hidden;
        top: 0;
        width: 100%;
        z-index: 9999;
    }

    &__overlay {
        background-color: #000;
        opacity: 0.85;
        position: fixed;
    }

    &__content {
        position: absolute;

        @media screen and (min-width: 1023px), screen and (min-height: 1023px) {
            height: 70%;
            left: 50%;
            max-width: 700px;
            right: auto;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }
    }

    &__iframe {
        height: 100%;
        padding-bottom: 80px;
        width: 100%;

        iframe {
            background-color: #fff;
            border: 0 none;
            display: block;
            height: 100%;
            width: 100%;
        }
    }

    &__footer {
        align-items: center;
        background: #fff;
        border-top: 1px solid #ccc;
        box-shadow: 0 10px 50px rgba(0, 0, 0, 0.2);
        display: flex;
        height: 80px;
        justify-content: center;
        margin-top: -80px;
        padding: 1em 0;
        position: relative;
        text-align: right;
        z-index: 1;

        @media screen and (min-width: 1023px), screen and (min-height: 1023px) {
            background-color: transparent;
            justify-content: space-between;
        }
    }

    &__text {
        color: #fff;
        display: none;
        flex: 1;
        margin: 0;
        text-align: left;
        width: 60%;

        @media screen and (min-width: 1023px), screen and (min-height: 1023px) {
            display: block;
        }
    }

    &__close,
    &__accept {
        -webkit-appearance: button;
        background-color: #0069ed;
        border: 0;
        color: #fff;
        cursor: pointer;
        font-family: inherit;
        font-size: 100%;
        font-weight: 700;
        line-height: 1.5;
        margin: 0;
        overflow: visible;
        padding: 1em 2em;
        text-transform: none;

        &:focus {
            outline: none;
        }
    }
    &__close {
        background: transparent;
        color: #999;
        padding-left: 0;

        @media screen and (min-width: 1023px), screen and (min-height: 1023px) {
            padding-left: 2em;
        }
    }
    &__accept {
        &:hover,
        &:focus {
            background-color: #0053ba;
        }
    }
}

html.is-overlay-active {
    &, body {
        overflow: hidden;
    }
}