@charset "UTF-8";

////
/// ============================================================================
/// Project .................... GDPR
/// Copyright .................. Meta Line S.r.l. - www.metaline.it
/// Author ..................... Daniele De Nobili @danieledenobili
/// Author ..................... Matteo Casonato @matteocasonato
/// ============================================================================
////

html {
	box-sizing: border-box;
}
*, *:before, *:after {
	box-sizing: inherit;
}

@import 'gdpr-modal';
@import 'gdpr-banner';
@import '_iubenda-custom';
