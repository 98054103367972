html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

.gdpr-modal {
  bottom: 0;
  font: 15px/1.5 system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999; }
  .gdpr-modal--overflow,
  .gdpr-modal--overflow body {
    overflow: hidden; }
  .gdpr-modal__overlay, .gdpr-modal__content {
    -webkit-overflow-scrolling: touch;
    height: 100%;
    left: 0;
    overflow: hidden;
    top: 0;
    width: 100%;
    z-index: 9999; }
  .gdpr-modal__overlay {
    background-color: #000;
    opacity: 0.85;
    position: fixed; }
  .gdpr-modal__content {
    position: absolute; }
    @media screen and (min-width: 1023px), screen and (min-height: 1023px) {
      .gdpr-modal__content {
        height: 70%;
        left: 50%;
        max-width: 700px;
        right: auto;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%); } }
  .gdpr-modal__iframe {
    height: 100%;
    padding-bottom: 80px;
    width: 100%; }
    .gdpr-modal__iframe iframe {
      background-color: #fff;
      border: 0 none;
      display: block;
      height: 100%;
      width: 100%; }
  .gdpr-modal__footer {
    align-items: center;
    background: #fff;
    border-top: 1px solid #ccc;
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.2);
    display: flex;
    height: 80px;
    justify-content: center;
    margin-top: -80px;
    padding: 1em 0;
    position: relative;
    text-align: right;
    z-index: 1; }
    @media screen and (min-width: 1023px), screen and (min-height: 1023px) {
      .gdpr-modal__footer {
        background-color: transparent;
        justify-content: space-between; } }
  .gdpr-modal__text {
    color: #fff;
    display: none;
    flex: 1;
    margin: 0;
    text-align: left;
    width: 60%; }
    @media screen and (min-width: 1023px), screen and (min-height: 1023px) {
      .gdpr-modal__text {
        display: block; } }
  .gdpr-modal__close, .gdpr-modal__accept {
    -webkit-appearance: button;
    background-color: #0069ed;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-family: inherit;
    font-size: 100%;
    font-weight: 700;
    line-height: 1.5;
    margin: 0;
    overflow: visible;
    padding: 1em 2em;
    text-transform: none; }
    .gdpr-modal__close:focus, .gdpr-modal__accept:focus {
      outline: none; }
  .gdpr-modal__close {
    background: transparent;
    color: #999;
    padding-left: 0; }
    @media screen and (min-width: 1023px), screen and (min-height: 1023px) {
      .gdpr-modal__close {
        padding-left: 2em; } }
  .gdpr-modal__accept:hover, .gdpr-modal__accept:focus {
    background-color: #0053ba; }

html.is-overlay-active, html.is-overlay-active body {
  overflow: hidden; }

/**
 * Base
 */
.gdpr-banner {
  background: #fff;
  color: #000;
  display: flex;
  flex-wrap: nowrap;
  font: 15px/1.5 system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  opacity: 1;
  overflow: hidden;
  padding: 1.5em;
  transition: opacity 0.2s ease; }
  @media screen and (max-width: 480px) {
    .gdpr-banner {
      align-items: unset;
      flex-direction: column; } }
  .gdpr-banner svg {
    fill: currentColor;
    flex-shrink: 0;
    height: 2em;
    margin-right: 1em;
    width: 2em; }
    .gdpr-banner svg:not(:root) {
      overflow: hidden; }
    @media screen and (max-width: 480px) {
      .gdpr-banner svg {
        display: none; } }
  .gdpr-banner p {
    margin: 0; }
  .gdpr-banner a {
    color: #000;
    cursor: pointer;
    display: inline-block;
    opacity: .8;
    padding: 0 .2em; }
    .gdpr-banner a:active, .gdpr-banner a:visited {
      color: inherit; }
    .gdpr-banner a:hover {
      color: #000;
      opacity: 1; }
  .gdpr-banner--hide {
    bottom: auto !important;
    left: -9999px !important;
    opacity: 0;
    position: fixed !important;
    right: auto !important; }
  .gdpr-banner__content {
    align-items: center;
    display: flex;
    flex: 1;
    padding-right: 1em; }
    @media screen and (max-width: 480px) {
      .gdpr-banner__content {
        margin-bottom: 1em;
        padding-right: 0; } }
  .gdpr-banner__accept {
    align-content: space-between;
    align-items: center;
    background-color: #0069ed;
    border: 0;
    border-radius: 0;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    display: flex;
    font-family: inherit;
    font-size: 100%;
    font-weight: 700;
    justify-content: center;
    line-height: 1.5;
    margin: 0;
    min-width: 160px;
    overflow: visible;
    padding: 0.5em 1em;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    transition: background 250ms ease-in-out, color 250ms ease-in-out, opacity 150ms ease;
    white-space: nowrap; }
    .gdpr-banner__accept:hover, .gdpr-banner__accept:focus {
      background-color: #0053ba; }
    .gdpr-banner__accept:focus {
      outline: none; }
    @media screen and (max-width: 480px) {
      .gdpr-banner__accept {
        flex: 1; } }

/**
 * Positions
 */
.gdpr-banner--sticky {
  position: fixed;
  z-index: 9999; }
  .gdpr-banner--sticky-top {
    left: 0;
    right: 0;
    top: 0; }
  .gdpr-banner--sticky-bottom {
    bottom: 0;
    left: 0;
    right: 0; }

.gdpr-banner--floating {
  flex-direction: column;
  position: fixed;
  z-index: 9999;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .gdpr-banner--floating {
      max-width: 480px; } }
  .gdpr-banner--floating svg {
    display: none; }
  .gdpr-banner--floating .gdpr-banner__content {
    display: block;
    margin-bottom: 1em;
    padding-right: 0; }
  .gdpr-banner--floating .gdpr-banner__accept {
    width: 100%; }
  .gdpr-banner--floating-topleft {
    top: 0; }
    @media screen and (min-width: 768px) {
      .gdpr-banner--floating-topleft {
        left: 1em;
        top: 1em; } }
  .gdpr-banner--floating-topcenter {
    left: 50%;
    right: auto;
    top: 0;
    transform: translateX(-50%); }
    @media screen and (min-width: 768px) {
      .gdpr-banner--floating-topcenter {
        top: 1em; } }
  .gdpr-banner--floating-topright {
    top: 0; }
    @media screen and (min-width: 768px) {
      .gdpr-banner--floating-topright {
        right: 1em;
        top: 1em; } }
  .gdpr-banner--floating-center {
    left: 50%;
    right: auto;
    top: 50%;
    transform: perspective(1px) translateX(-50%) translateY(-50%); }
  .gdpr-banner--floating-bottomleft {
    bottom: 0; }
    @media screen and (min-width: 768px) {
      .gdpr-banner--floating-bottomleft {
        bottom: 1em;
        left: 1em; } }
  .gdpr-banner--floating-bottomcenter {
    bottom: 0;
    left: 50%;
    right: auto;
    transform: translateX(-50%); }
    @media screen and (min-width: 768px) {
      .gdpr-banner--floating-bottomcenter {
        bottom: 1em; } }
  .gdpr-banner--floating-bottomright {
    bottom: 0; }
    @media screen and (min-width: 768px) {
      .gdpr-banner--floating-bottomright {
        bottom: 1em;
        right: 1em; } }

/**
 * Styles
 */
.gdpr-banner--mono {
  background-color: #237afc;
  color: #fff; }
  .gdpr-banner--mono a {
    color: #fff; }
    .gdpr-banner--mono a:hover {
      color: #fff; }
  .gdpr-banner--mono .gdpr-banner__accept {
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    opacity: .8; }
    .gdpr-banner--mono .gdpr-banner__accept:hover, .gdpr-banner--mono .gdpr-banner__accept:focus {
      background-color: transparent;
      opacity: 1; }

.gdpr-banner--honeybee {
  background-color: #000;
  color: #fff; }
  .gdpr-banner--honeybee a {
    color: #fff; }
    .gdpr-banner--honeybee a:hover {
      color: #fff; }
  .gdpr-banner--honeybee .gdpr-banner__accept {
    background-color: #f1d600;
    color: #000; }
    .gdpr-banner--honeybee .gdpr-banner__accept:hover, .gdpr-banner--honeybee .gdpr-banner__accept:focus {
      background-color: #fffc26; }

.gdpr-banner--cosmo {
  background-color: #3937a3;
  color: #fff; }
  .gdpr-banner--cosmo a {
    color: #fff; }
    .gdpr-banner--cosmo a:hover {
      color: #fff; }
  .gdpr-banner--cosmo .gdpr-banner__accept {
    background-color: #e62576;
    color: #fff; }
    .gdpr-banner--cosmo .gdpr-banner__accept:hover, .gdpr-banner--cosmo .gdpr-banner__accept:focus {
      background-color: #ff4b9c; }

.gdpr-banner--elegant {
  background-color: #212121;
  color: #fff;
  padding: 0; }
  .gdpr-banner--elegant a {
    color: #fff; }
    .gdpr-banner--elegant a:hover {
      color: #fff; }
  .gdpr-banner--elegant .gdpr-banner__content {
    padding: 2em; }
    @media screen and (max-width: 480px) {
      .gdpr-banner--elegant .gdpr-banner__content {
        margin-bottom: 0; } }
  .gdpr-banner--elegant .gdpr-banner__accept {
    background-color: transparent;
    border-left: 1px solid #626265;
    color: #fff;
    font-weight: 400;
    height: 100%;
    padding-bottom: 3em;
    padding-top: 3em; }
    .gdpr-banner--elegant .gdpr-banner__accept:hover, .gdpr-banner--elegant .gdpr-banner__accept:focus {
      color: #dadada; }
    @media screen and (max-width: 480px) {
      .gdpr-banner--elegant .gdpr-banner__accept {
        border-left: 0;
        border-top: 1px solid #626265;
        padding-bottom: 1em;
        padding-top: 1em; } }
  .gdpr-banner--elegant.gdpr-banner--floating .gdpr-banner__content {
    margin-bottom: 0; }
  .gdpr-banner--elegant.gdpr-banner--floating .gdpr-banner__accept {
    border-left: 0;
    border-top: 1px solid #626265;
    padding-bottom: 1em;
    padding-top: 1em; }

[class^="iub"] > h1:first-of-type,
[class^="iub"] > h2:first-of-type,
[class^="iub"] > br {
  display: none; }

[class^="iub"] > ul h3 {
  font-size: 1em;
  margin-bottom: 1rem;
  font-weight: bold; }
