////
/// ============================================================================
/// PERSONALIZZAZIONI PAGINE IUBENDA
/// ============================================================================
////

[class^="iub"] > {
	h1:first-of-type,
	h2:first-of-type,
	br {
		display: none;
	}

	ul h3 {
		font-size: 1em;
		margin-bottom: 1rem;
		font-weight: bold;
	}
}
